import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
// import { seo, intro, main } from '../content/uslugi'
import { useLangContext } from '../context/lang.context'
import { useFormContext } from '../context/form.context'
import Intro from '../components/Intro'
import Button from '../components/Button'
import { sLinkTiles } from '../style'
import LinkTile from '../components/LinkTile'
import Main from '../components/Main'


export const seo = {
  url: 'spacer-360-gdynia',
  title: {
    pl: 'Spacer 360 - Gdynia',
    en: 'Spacer 360 - Gdynia',
    ua: 'Spacer 360 - Gdynia',
  },

  desc: {
    pl: ``,
    en: ``,
    ua: ``,
  },
  keywords: [],
}

const Spacer360 = () => {
  const { lang } = useLangContext()

  return (
    <>
    <Seo
      title={seo.title[lang]}
      desc={seo.desc[lang]}
      url={seo.url}
      keywords={seo.keywords}
    />
    <div css={sIframeContainer} dangerouslySetInnerHTML={{ __html: '<iframe src="https://webobook.com/public/6321f688ec41d851c2205922,en?ap=false&si=true&sm=false&sp=false&sfr=true&sl=true&sop=false&" allow="xr-spatial-tracking; vr; gyroscope; accelerometer; fullscreen; autoplay; xr" scrolling="no" allowfullscreen="true"  frameborder="0" />'}} />
    </>
  )
}

const sIframeContainer = {
  iframe: {
    width: '100%', 
    height: '100vh',
    border: '0',
    maxWidth: '100%',
  }
}

export default Spacer360
